<template>
	<div class="content">
		<div class="title-actions">
			<h5 class="title">{{ $t('countries') }}</h5>
			<div class="btns">
				<input
					type="search"
					v-model="filter"
					class="form-control"
					:placeholder="$t('search')"
					autofocus
				/>
				<b-button variant="info" @click="create">
					<i class="fa fa-plus"></i>
				</b-button>
				<b-button @click="$router.go(-1)">
					<i class="fa fa-arrow-left"></i>
				</b-button>
			</div>
		</div>
		<hr />
		<b-table
			striped
			bordered
			:items="countries"
			:fields="columns"
			:filter="filter"
		>
			<div slot="name" slot-scope="row">
				<img
					:src="row.item.flag"
					:alt="row.item.name"
					class="img-fluid mr-3"
					width="30"
				/>
				<span>{{ row.item.name }}</span>
			</div>
			<template slot="actions" slot-scope="row">
				<div class="w-100 text-center">
					<b-dropdown id="ddDivisionOptions" right noCaret>
						<div slot="button-content" class="px-2">
							<i class="fa fa-ellipsis-v"></i>
						</div>
						<b-dropdown-item @click.prevent="edit(row.item)">{{
							$t('edit')
						}}</b-dropdown-item>
						<b-dropdown-item @click.prevent="openModalDestroy(row.item)">
							<i class="fa fa-trash text-danger"></i>
							<span class="ml-3 text-danger">{{ $t('delete') }}</span>
						</b-dropdown-item>
					</b-dropdown>
				</div>
			</template>
		</b-table>
		<b-form @submit.prevent="onSubmit">
			<b-modal id="modalCountry" :title="modalTitle">
				<div class="form-group">
					<label for="file"
						>{{ $t('findImage') }} -
						<small class="text-muted">*.jpg | 55 x 35px</small></label
					>
					<div
						class="custom-file"
						:class="validateState('files') ? 'is-valid' : 'is-invalid'"
					>
						<input
							type="file"
							ref="inputFile"
							class="custom-file-input"
							id="file"
							:lang="lang"
							@change="imagePreview($event)"
							v-validate="'image|ext:jpg|size:1024'"
							accept=".png"
							name="files"
							:data-vv-as="$t('image') | toLowerCase"
						/>
						<label class="custom-file-label" ref="lblFileName" for="file">{{
							$t('findImage')
						}}</label>
					</div>
					<div class="text-center mt-3" v-if="country.flag">
						<img
							:src="country.flag"
							alt="Vista previa"
							class="img-fluid img-thumbnail"
						/>
					</div>
				</div>
				<div class="form-group">
					<label for="end">{{ $t('name') }}</label>
					<b-form-input
						v-model="country.name"
						name="name"
						id="name"
						v-validate="'required|max:50'"
						:state="validateState('name')"
					/>
					<span class="error-inputs">{{ errors.first('name') }}</span>
				</div>
				<template slot="modal-footer">
					<b-button
						type="button"
						@click="$root.$emit('bv::hide::modal', 'modalCountry')"
						>{{ $t('cancel') }}</b-button
					>
					<b-button type="submit" variant="primary">{{ $t('save') }}</b-button>
				</template>
			</b-modal>
		</b-form>
		<b-modal
			ref="modalDelete"
			:title="$t('confirmTitleDelete')"
			headerBgVariant="danger"
			headerTextVariant="light"
		>
			<p>{{ $t('confirmTextDelete') }}</p>
			<strong>{{ country.name }}</strong>
			<div slot="modal-footer">
				<b-button @click="$refs.modalDelete.hide()">{{
					$t('cancel')
				}}</b-button>
				<b-button variant="danger" @click="destroy" class="ml-3">{{
					$t('delete')
				}}</b-button>
			</div>
		</b-modal>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
	data() {
		return {
			filter: '',
			country: {
				flag: null,
			},
			columns: [
				{ key: 'name', label: this.$t('country'), sortable: true },
				{ key: 'actions', label: '' },
			],
			modalTitle: '',
			file: [],
			countries: [],
			action: null,
		}
	},
	computed: {
		...mapGetters(['lang', 'console']),
	},
	mounted() {
		document.title = `${this.$t('countries')} - ITFA`
		this.fetchData()
	},
	methods: {
		openModalDestroy(country) {
			this.country = country
			this.$refs.modalDelete.show()
		},
		destroy() {
			const id = this.country.id
			const path = `auth/${this.lang}/${this.console}/countries/${id}/delete`
			this.$axios.delete(path).then(() => {
				this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
				this.$refs.modalDelete.hide()
				this.countries = this.countries.filter((e) => e.id !== id)
			})
		},
		imagePreview(e) {
			const input = e.target
			this.file = input.files[0]
			const _self = this
			if (input.files && input.files[0]) {
				_self.$refs.lblFileName.innerHTML = this.file.name
				var reader = new FileReader()
				reader.onload = function(e) {
					_self.country.flag = e.target.result
				}
				reader.readAsDataURL(input.files[0])
			} else {
				_self.file = []
				_self.$refs.lblFileName.innerHTML = _self.$t('findImage')
			}
			this.$forceUpdate()
		},
		onSubmit() {
			this.$validator.validate().then((result) => {
				if (result) {
					const payload = new FormData()
					payload.append('name', this.country.name)
					payload.append('flag', this.file)
					if (this.action === 'STORE') {
						const path = `auth/${this.lang}/${this.console}/countries/store`
						this.$axios.post(path, payload).then((response) => {
							this.countries.unshift(response.data.data)
							this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
							this.clearScreen()
						})
					} else {
						const id = this.country.id
						const path = `auth/${this.lang}/${
							this.console
						}/countries/${id}/update`
						this.$axios.post(path, payload).then(() => {
							this.countries = this.countries.map((e) => {
								if (e.id === id) {
									return this.country
								}
								return e
							})
							this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
							this.clearScreen()
						})
					}
				} else {
					this.showValidationsErrors(this.errors.items)
				}
			})
		},
		clearScreen() {
			this.$root.$emit('bv::hide::modal', 'modalCountry')
			this.country = {
				flag: null,
			}
			this.action = null
		},
		edit(country) {
			this.action = 'UPDATE'
			this.modalTitle = this.$t('edit')
			this.country = JSON.parse(JSON.stringify(country))
			this.$root.$emit('bv::show::modal', 'modalCountry')
		},
		create() {
			this.action = 'STORE'
			this.modalTitle = this.$t('add')
			this.country = {
				flag: null,
			}
			this.$root.$emit('bv::show::modal', 'modalCountry')
		},
		fetchData() {
			const path = `auth/${this.lang}/${this.console}/countries/all`
			this.$axios.get(path).then((response) => {
				this.countries = response.data.data
			})
		},
	},
}
</script>
